import { render, staticRenderFns } from "./LuggageConfirm.vue?vue&type=template&id=3f9d133c&scoped=true"
import script from "./LuggageConfirm.vue?vue&type=script&lang=js"
export * from "./LuggageConfirm.vue?vue&type=script&lang=js"
import style0 from "./LuggageConfirm.vue?vue&type=style&index=0&id=3f9d133c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f9d133c",
  null
  
)

export default component.exports