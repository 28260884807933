const axios = require('axios').default;

// const controller = new AbortController();

const { CancelToken } = axios;

let source = CancelToken.source();

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.patch['Content-Type'] = 'application/json; charset=utf-8';

const { VUE_APP_API_ENDPOINT, VUE_APP_BFF_ENDPOINT_STAGING, VUE_APP_ADMIN_ENDPOINT,
  VUE_APP_HBA_ENDPOINT, VUE_APP_DOMESTIC_ENDPOINT, VUE_APP_FLYINGISR_ENDPOINT,
  VUE_APP_AZURE_ADMIN_ENDPOINT, VUE_APP_AZURE_FLYINGISR_ENDPOINT, VUE_APP_AZURE_DOMESTIC_ENDPOINT, VUE_APP_AZURE_MAIN_DOMAIN,
} = process.env;

function getHost(pKindHost, pStagingMode) {
  const { host } = window.location;
  const azureMode = host.includes('new') || host.includes('saflycptpckfprodwsteur01');

  let domain = '';
  switch (pKindHost) {
    case 'MAINTENANCE':
      // eslint-disable-next-line no-nested-ternary
      domain = azureMode ? VUE_APP_AZURE_ADMIN_ENDPOINT : (pStagingMode ? VUE_APP_BFF_ENDPOINT_STAGING : VUE_APP_ADMIN_ENDPOINT);
      break;
    case 'STAGING':
      domain = VUE_APP_BFF_ENDPOINT_STAGING;
      break;
    case 'BFF':
      // eslint-disable-next-line no-nested-ternary
      domain = azureMode ? VUE_APP_AZURE_FLYINGISR_ENDPOINT : (pStagingMode ? VUE_APP_BFF_ENDPOINT_STAGING : VUE_APP_FLYINGISR_ENDPOINT);
      break;
    case 'HBA':
      domain = VUE_APP_HBA_ENDPOINT;
      break;
    case 'DOMESTIC':
      domain = azureMode ? VUE_APP_AZURE_DOMESTIC_ENDPOINT : VUE_APP_DOMESTIC_ENDPOINT;
      break;
    default:
      domain = azureMode ? VUE_APP_AZURE_MAIN_DOMAIN : VUE_APP_API_ENDPOINT;
  }
  return domain;
}

function getAuthHeader(context, jwt = undefined, formData) {
  const config = (formData) ? { headers: { 'Content-Type': 'multipart/form-data' } } : { headers: {} };
  if (context && context.rootState.whiteLabel && context.rootState.whiteLabel.agencyCode) {
    config.headers.agency = context.rootState.whiteLabel.agencyCode;
  }
  if (context && context.rootState.agencyUsers && context.rootState.agencyUsers.marketerId) {
    config.headers.agency = context.rootState.agencyUsers.marketerId;
  }
  if (jwt || (context && context.rootState.users && context.rootState.users.userToken)) {
    config.headers.Authorization = `bearer ${jwt || context.rootState.users.userToken}`;
  }
  if (context && context.rootState.memberUsers.userMemberSid) {
    config.headers.memberSid = context.rootState.memberUsers.userMemberSid;
  }
  if (context && context.rootState.agencyUsers.userAgencySid) {
    config.headers.agencySid = context.rootState.agencyUsers.userAgencySid;
  }
  if (context && context.rootState.visitorUsers.visitorSid) {
    config.headers.visitorSid = context.rootState.visitorUsers.visitorSid;
  }
  if (context && context.rootState.agencyUsers.isFcAgentMarketerMode) {
    config.headers['fc-agent-mode'] = '';
  }
  config.headers.staging = true;
  return config;
}

function getAuthHeaderText(context) {
  const config = { headers: { 'content-type': 'text/plain; charset=utf-8' } };
  if (context && context.rootState.whiteLabel && context.rootState.whiteLabel.code) {
    config.headers.agency = context.rootState.whiteLabel.code;
  }
  return config;
}

function cancelGetRequest() {
  // This API is deprecated since v0.22.0 and shouldn't be used in new projects
  // Current version of axios is 0.21.0
  source.cancel('Operation canceled by the user.');
  source = CancelToken.source();
}

function getWithoutAutherization(endpoint, url) {
  const { host } = window.location;
  const staging = host.includes('localhost') || host.includes('vercel');

  const selHost = getHost(endpoint, staging);
  return axios.get(`${selHost}${url}`);
}

function get(endpoint, url, context = undefined) {
  const headers = getAuthHeader(context),
    host = getHost(endpoint, context?.rootState?.stagingMode ?? false);
  headers.cancelToken = source.token;
  return axios.get(`${host}${url}`, headers);
}

function post(endpoint, url, body, context, jwt, formData) {
  const headers = getAuthHeader(context, jwt, formData),
    host = getHost(endpoint, context?.rootState?.stagingMode);
  return axios.post(`${host}${url}`, body, headers);
}

function postWithText(endpoint, url, body, context, jwt) {
  const headers = getAuthHeaderText(context, jwt),
    host = getHost(endpoint, context?.rootState?.stagingMode);
  return axios.post(`${host}${url}`, body, headers);
}

function put(endpoint, url, body, context, jwt) {
  const headers = getAuthHeader(context, jwt, true),
    host = getHost(endpoint, context?.rootState?.stagingMode);
  return axios.post(`${host}${url}`, body, headers);
}

function patch(endpoint, url, body, context, jwt) {
  const headers = getAuthHeader(context, jwt),
    host = getHost(endpoint, context?.rootState?.stagingMode);
  return axios.post(`${host}${url}`, body, headers);
}

function deleteApi(endpoint, url, body, context, jwt) {
  const headers = getAuthHeader(context, jwt),
    host = getHost(endpoint, context?.rootState?.stagingMode);
  return axios.post(`${host}${url}`, body, headers);
}

function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepCopy(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = deepCopy(obj[key]);
      return newObj;
    }, {});
  }

  return obj;
}

const utf8ToB64 = (str) => (window.btoa(unescape(encodeURIComponent(str))));
const b64ToUtf8 = (str) => (decodeURIComponent(escape(window.atob(str))));
const validateEmail = (email) => Boolean(email.match(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
));
export {
  getWithoutAutherization, validateEmail,
  get, post, patch, deleteApi, put, deepCopy, postWithText, utf8ToB64, b64ToUtf8, cancelGetRequest,
};
