<template>
<div class="mx-2">
  <h3 class="my-3" v-if="device==='desktop'">
    <strong v-if="data.firstName || data.lastName">{{ $t('booking.passenger') }} {{ passengerIndex }} - {{ `${data.firstName} ${data.lastName}` }}</strong>
    <strong v-else>{{ $t('booking.passenger') }} {{ passengerIndex }} </strong>
  </h3>

  <LuggageOneDirectionSelection returnDepartureType="departure" :data="departurePrices" @setChargePrice="setDepartureCharged" :flight="departureFlightData"/>
  <LuggageOneDirectionSelection returnDepartureType="return" :data="returnPrices" @setChargePrice="setReturnCharged" :flight="returnFlightData"/>

</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'luggage-one-user-selection',
  components: {
    LuggageOneDirectionSelection: () => import('@/components/booking/atoms/LuggageOneDirectionSelection'),
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    inx: {
      type: Number,
      default: 0,
    },
    priceData: {
      type: Array,
      default: () => [],
    },
    flightData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    departureFlightData() {
      return this.flightData?.FlightDetail?.[0] || null;
    },
    returnFlightData() {
      return this.flightData?.FlightDetail?.[1] || null;
    },
    passengerIndex() {
      return this.inx + 1;
    },
    departurePrices() {
      return this.priceData.find((price) => !price.returningLeg);
    },
    returnPrices() {
      return this.priceData.find((price) => price.returningLeg);
    },
  },
  mounted() {
    this.$store.commit('INIT_CHARGED_BAGGAGE_DATA', {
      passengerRefId: `${this.passengerIndex}`,
    });
  },
  methods: {
    setDepartureCharged(price) {
      this.$store.commit('SET_CHARGED_BAGGAGE_DATA', {
        passengerRefId: `${this.passengerIndex}`,
        returningLeg: false,
        trolleyCharge: price.trolley,
        suitcaseCharge: price.suitcase,
      });
    },
    setReturnCharged(price) {
      this.$store.commit('SET_CHARGED_BAGGAGE_DATA', {
        passengerRefId: `${this.passengerIndex}`,
        returningLeg: true,
        trolleyCharge: price.trolley,
        suitcaseCharge: price.suitcase,
      });
    },
  },
};

</script>
