<template>
  <div>
    <section class="flight_only_area" v-if="isLoading">
      <ContentLoading type="product" />
    </section>
    <section class="flight_only_area" v-else>
      <Breadcrumb type="area" :data="linkData" v-if="linkData && marketerId===''" />
      <Banner :banner="bannerImage" />
      <div class="container" ref='flight'>
        <div class="row">
          <div class="col-lg-12">
            <div class="flight_only_body pt-4">
              <!-- <h2>{{ $t("flight-only.flight-details") }}</h2> -->
              <share-this type="product" v-if="departureItem" :shareContent="shareData"/>
              <associated-products-list
                category="Flight_Only"
                :productsList="associatedProducts"
                @update="updateProduct"
                v-if="isAssociated && associatedProducts && associatedProducts.length"/>
              <br/>
              <div class="share-button" @click="showShareThisModal" v-if="device==='mobile'">
                <svg xmlns="http://www.w3.org/2000/svg" class="share-icon" viewBox="0 0 512 512">
                  <path d="M378,324a69.78,69.78,0,0,0-48.83,19.91L202,272.41a69.68,69.68,0,0,0,0-32.82l127.13-71.5A69.76,69.76,0,1,0,308.87,129l-130.13,73.2a70,70,0,1,0,0,107.56L308.87,383A70,70,0,1,0,378,324Z" fill="#28a745"></path></svg>
              </div>

              <div v-if="!availableFlight && isAppDealPage">
                <section class="plaindetails-area">
                  <div class="container">
                    <div class="plaindetails-box">
                      <h5>{{ $t("flight-only.unavailable-flight") }}</h5>
                    </div>
                  </div>
                </section>
              </div>

              <FlightBoxOne type="depature" :data="departureItem" :airInfo="airInfoDepart"/>
              <FlightBoxTwo type="depature" :data="departureItem" />
              <FlightBoxOne type="return" :data="returnItem" :airInfo="airInfoReturn" />
              <FlightBoxTwo type="return" :data="returnItem" />

              <div class="select-flight d-flex mb-3"  v-if="extraFlights.length>1">
                <div class="selectflhtbtn m-auto" id="selectflhtbtn" v-b-toggle="`filter-content`">
                  <img :src="`${imageDomain}/assets/img/flight.png`" alt="flight" width="15" height="15"/>{{ $t('product-page.more-flight-option') }}
                </div>
              </div>
              <b-collapse class="filter-cont dVacation-pack" :id="`filter-content`" v-model="visibleMoreFlights">
                <FlightOnlyItem
                  v-for="(item, inx) in extraFlights"
                  :key="inx"
                  :flight="item"
                  :idx="inx"
                  :selectIdx="idxFlight"
                  categoryName="Flight_Only"
                  type="product"
                  @change="changeFlightIndex"
                />
              </b-collapse>

              <FlightBoxThree :product="product" :airInfo="airInfoDepart" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer v-if="device === 'desktop' && !isAppDealPage"/>

    <b-modal id="share-this-modal" v-model="shareThisModal">
      <template #modal-header>
        <h4> {{ $t("share-this.share-modal-title") }} </h4>
      </template>
      <template #default>
        <ShareThis :shareContent="shareData"/>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          {{$t('booking.close')}}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { BCollapse, VBToggle, BButton } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';

import TemplateFlightOnly from './TemplateFlightOnly';

export default {
  name: 'FlightOnly',
  mixins: [gMixin, TemplateFlightOnly],
  components: {
    BCollapse,
    BButton,
    Banner: () => import('@/components/productPage/ProductBanner'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    FlightBoxOne: () => import('@/components/bookingFlightOnly/FlightBoxOne'),
    FlightBoxTwo: () => import('@/components/bookingFlightOnly/FlightBoxTwo'),
    FlightBoxThree: () => import('@/components/bookingFlightOnly/FlightBoxThree'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
    FlightOnlyItem: () => import('@/components/searchResult/atoms/FlightOnlyItem'),
    ShareThis: () => import('@/components/atoms/ShareThis'),
    AssociatedProductsList: () => import('@/components/productPage/page/AssociatedProductsSelection'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    isAssociated() {
      const { query } = this.$route;
      return Object.prototype.hasOwnProperty.call(query, 'associated');
    },
    dest() {
      return this.departureItem?.translations?.flightDestinationName?.[this.departureItem.FL_To_Route]?.[this.lang]
        || this.departureItem?.FL_To_Route || '';
    },
    shareData() {
      const price = this.product?.flightOnlyPrice?.find((item) => item.age_from === 0 && item.age_to === 0).price || '';
      const priceStr = price ? this.getPriceWithSymbol(this.product.cc, price) : '';
      return {
        category: 'Flight_Only', // this.product.category.dealTypeCode,
        dest: this.dest,
        airline: this.departureItem.arl_Name,
        image: this.bannerImage,
        link: `${window.location.pathname}${window.location.search}`,
        country: '',
        rate: priceStr,
      };
    },
  },
  mounted() {
    const { dest } = this.$route.query;
    if (this.isAssociated && dest) {
      this.$store.dispatch('FETCH_ASSOCIATED_PRODUCTS', {
        dest,
        category: 'Flight_Only',
      });
    }
    window.sessionStorage.removeItem('baggagePrices');
  },
  data() {
    return {
      shareThisModal: false,
    };
  },
  methods: {
    setFlightOfProduct() {
      this.product.discounted = 0;
      this.product.discountPercent = 0;
      this.product.category = this.category;
      // this.product.packSelectionId = this.packSelectionId;

      const activeFlight = this.extraFlights[this.idxFlight];
      if (!activeFlight) return;
      const OFObject = activeFlight.FlightDetail[0], IFObject = activeFlight.FlightDetail[1];
      const outwardFlight = {
          flightId: OFObject.FL_ID,
          departureCityCode: OFObject.FL_From_Air_Port,
          departureDate: OFObject.FL_Date,
          departureTime: OFObject.FL_Dep_Hour,
          arrivalCity: OFObject.FL_To_Air_Port,
          class: activeFlight.Class1,
          paxIds: [],
        },
        inwardFlight = {
          flightId: IFObject.FL_ID,
          departureCityCode: IFObject.FL_From_Air_Port,
          departureDate: IFObject.FL_Date,
          departureTime: IFObject.FL_Dep_Hour,
          arrivalCity: IFObject.FL_To_Air_Port,
          class: activeFlight.Class2,
          paxIds: [],
        };
      this.product.outwardFlight = outwardFlight;
      this.product.inwardFlight = inwardFlight;

      // let activePackage = null;
      // for (let i = 0; i < this.packages.length; i += 1) {
      //   if (this.packages[i].packId === Number(this.query.packId)) {
      //     activePackage = this.packages[i];
      //     break;
      //   }
      // }
      // if (!activePackage) return;
      const activePackage = this.packages[0];
      let barCode = activeFlight.FlightDetail[0].FL_AIRLINE;
      // if (this.product.airlineInfo && this.product.airlineInfo[barCode]) this.airInfoDepart = this.product.airlineInfo[barCode];
      if (activePackage && activePackage.airlineInfo && activePackage.airlineInfo[barCode]) this.airInfoDepart = activePackage.airlineInfo[barCode];

      barCode = activeFlight.FlightDetail[1].FL_AIRLINE;
      // if (this.product.airlineInfo && this.product.airlineInfo[barCode]) this.airInfoReturn = this.product.airlineInfo[barCode];
      if (activePackage && activePackage.airlineInfo && activePackage.airlineInfo[barCode]) this.airInfoReturn = activePackage.airlineInfo[barCode];

      activeFlight.FlightDetail[0].airlineInfo = this.airInfoDepart;
      activeFlight.FlightDetail[1].airlineInfo = this.airInfoReturn;
      this.product.flights = [activeFlight];

      this.product.flights.forEach((itemFlight) => {
        itemFlight.translations = this.product.translations;
      });

      this.departureItem = activeFlight.FlightDetail[0];
      this.departureItem.translations = this.product.translations;
      this.returnItem = activeFlight.FlightDetail[1];
      this.returnItem.translations = this.product.translations;

      window.sessionStorage.setItem('production', JSON.stringify(this.product));
      window.sessionStorage.setItem('baggagePrices', this.product.chargedBaggagePrices ? JSON.stringify(this.product.chargedBaggagePrices) : '');

      this.$root.$emit('send-share-data', this.shareData);
    },
    showShareThisModal() {
      this.shareThisModal = true;
    },
    updateProduct() {
      this.fetchData();
    },
  },
};

</script>
<style>
.productPage .btnDomestic {display:none !important;}
</style>

<style scoped>
.share-button {
  transform: translate(0px, -40px);
}
</style>
