<template>
<div class="body_box">
  <div class="d-flex">
    <div class="form-group col-6 m-0 p-0">
      <input
        required
        type="text"
        :state="stateFirstName"
        :class="['form-control', !stateFirstName ? 'danger' : '']"
        :placeholder="$t('booking.first-name')"
        v-model="firstNamePayer"
        @change="updatingPayerInfo"
      />
      <div class="icon-form" v-if="stateFirstName"><i class="fas fa-check"></i></div>
    </div>
    <div class="form-group col-6 m-0 p-0">
      <input
        type="text"
        :class="['form-control', !stateLastName ? 'danger' : '']"
        :placeholder="$t('booking.last-name')"
        v-model="lastNamePayer"
        @change="updatingPayerInfo"
      />
      <div class="icon-form" v-if="stateLastName"><i class="fas fa-check"></i></div>
    </div>
  </div>
  <div class="form-group my-3">
    <div class="w-100 position-relative ">
      <input
        type="text"
        :class="['form-control', !stateIDCardNumber ? 'danger' : '']"
        :placeholder="$t('booking.id-card')"
        v-model="idCardNumberPayer"
        @change="updatingPayerInfo"
      />
      <div class="icon-form" v-if="stateIDCardNumber"><i class="fas fa-check"></i></div>
    </div>
  </div>
  <div class="form-group m-0">
    <div class="w-100 position-relative ">
      <b-form-select
        v-model="NoOfPayment"
        :options="paymentOptions"
        @change="updatingPayerInfo"
        class="form-control"
      ></b-form-select>
    </div>
  </div>
</div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';

export default {
  name: 'rest-payer-information',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    payer: {
      type: Object,
      default: null,
    },
    paymentOptions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BFormSelect,
  },
  data() {
    return {
      firstNamePayer: '',
      lastNamePayer: '',
      idCardNumberPayer: '',
      NoOfPayment: '1',
    };
  },
  watch: {
    idCardNumberPayer() {
      if (!this.idCardNumberPayer) return;
      const length = 9 - String(this.idCardNumberPayer).length;
      if (length > 0) {
        const str = new Array(length + 1).join('0');
        this.idCardNumberPayer = `${str}${this.idCardNumberPayer}`;
      } else if (length < 1) {
        this.idCardNumberPayer = this.idCardNumberPayer.substr(-length);
      }
    },
  },
  computed: {
    stateFirstName() {
      return !!this.firstNamePayer && this.firstNamePayer !== '';
    },
    stateLastName() {
      return !!this.lastNamePayer && this.lastNamePayer !== '';
    },
    stateIDCardNumber() {
      return !!this.idCardNumberPayer && this.idCardNumberPayer !== '';
    },
  },
  created() {
    this.initVariable();
  },
  methods: {
    initVariable() {
      this.firstNamePayer = this.payer.firstNamePayer || '';
      this.lastNamePayer = this.payer.lastNamePayer || '';
      this.idCardNumberPayer = this.payer.idCardNumberPayer || 0;
      this.NoOfPayment = this.payer.NoOfPayment || 0;
      this.available = false;
    },
    updatingPayerInfo() {
      const payer = {
        firstNamePayer: this.firstNamePayer,
        lastNamePayer: this.lastNamePayer,
        idCardNumberPayer: this.idCardNumberPayer,
        NoOfPayment: Number(this.NoOfPayment),
        available: false,
      };
      payer.available = this.availablePayerInformation();
      this.$emit('updateRestPayer', this.index, payer);
    },
    availablePayerInformation() {
      if (this.stateFirstName
        && this.stateLastName
        && this.idCardNumberPayer
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

};
</script>
<style scoped>

.form-group .icon-form{
    content: '';
    position: absolute;
    bottom: 5px;
    left: 14px;
    color: #28a745;
}
.danger {
  border-color: #FF5733;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(233 102 102 / 60%);
}
</style>
