<template>
  <div class="hotel_details_area">
    <div class="hotel_details_heading">
      <h3>
        <span class="icon">
            <i class="fas fa-suitcase"></i>
          </span>
        {{ $t("flight-only.baggage-details-title") }}
      </h3>
    </div>
    <div class="hotel_details_body">
      <div class="row">
        <div class="col-lg-3 col-12 p-2" v-for="(user, inx) in userList" :key="user.id">
          <div class="one-passenger-box py-2 px-3">
            <h4>{{ $t('booking.passenger') }} {{ user.id }}: {{ `${user.firstName} ${user.lastName}` }}</h4>

            <div v-if="departureFlightData" class="my-2">

              <h5>{{ $t('price-plan.departure-flight') }}</h5>

              <div class="d-flex justify-content-between">
                <div>
                  <span class="icon ml-2">
                    <i class="fas fa-shopping-bag"></i>
                  </span>
                  <span class="include-ok">
                    ✔
                  </span>
                  <span>{{ `${outward.handbagIncluding} kg` }}</span>
                </div>
                <span>{{ $t('check-booking.handbag-included') }}</span>
              </div>

              <div class="d-flex justify-content-between">
                <div>
                  <span class="icon ml-2">
                    <i class="fas fa-suitcase-rolling"></i>
                  </span>
                  <span class="include-ok" v-if="depTrolleyChargedPrice === null || getDepTrolleyCharged(inx)">
                    ✔
                  </span>
                  <span class="include-not" v-else>
                    ✘
                  </span>
                  <span>{{ `${outward.trolleyIncluding} kg` }}</span>
                </div>
                <span v-if="depTrolleyChargedPrice === null || getDepTrolleyCharged(inx)">{{ $t('check-booking.trolley-included') }}</span>
                <span v-else>{{ $t('check-booking.trolley-not-included') }}</span>
              </div>

              <div class="d-flex justify-content-between">
                <div>
                  <span class="icon ml-2">
                    <i class="fas fa-suitcase"></i>
                  </span>
                  <span class="include-ok" v-if="depSuitcaseChargedPrice === null || getDepSuitcaseCharged(inx)">
                    ✔
                  </span>
                  <span class="include-not" v-else>
                    ✘
                  </span>
                  <span>{{ `${outward.suitcaseIncluding} kg` }}</span>
                </div>
                <span v-if="depSuitcaseChargedPrice === null || getDepSuitcaseCharged(inx)">{{ $t('check-booking.suitcase-included') }}</span>
                <span v-else>{{ $t('check-booking.suitcase-not-included') }}</span>
              </div>
            </div>

            <div v-if="returnFlightData" class="my-2">

              <h5>{{ $t('price-plan.return-flight') }}</h5>

              <div class="d-flex justify-content-between">
                <div>
                  <span class="icon ml-2">
                    <i class="fas fa-shopping-bag"></i>
                  </span>
                  <span class="include-ok">
                    ✔
                  </span>
                  <span>{{ `${inward.handbagIncluding} kg` }}</span>
                </div>
                <span>{{ $t('check-booking.handbag-included') }}</span>
              </div>

              <div class="d-flex justify-content-between">
                <div>
                  <span class="icon ml-2">
                    <i class="fas fa-suitcase-rolling"></i>
                  </span>
                  <span class="include-ok" v-if="retTrolleyChargedPrice === null || getRetTrolleyCharged(inx)">
                    ✔
                  </span>
                  <span class="include-not" v-else>
                    ✘
                  </span>
                  <span>{{ `${inward.trolleyIncluding} kg` }}</span>
                </div>
                <span v-if="retTrolleyChargedPrice || getRetTrolleyCharged(inx)">{{ $t('check-booking.trolley-included') }}</span>
                <span v-else>{{ $t('check-booking.trolley-not-included') }}</span>
              </div>

              <div class="d-flex justify-content-between">
                <div>
                  <span class="icon ml-2">
                    <i class="fas fa-suitcase"></i>
                  </span>
                  <span class="include-ok" v-if="retSuitcaseChargedPrice === null || getRetSuitcaseCharged(inx)">
                    ✔
                  </span>
                  <span class="include-not" v-else>
                    ✘
                  </span>
                  <span>{{ `${inward.suitcaseIncluding} kg` }}</span>
                </div>
                <span v-if="retSuitcaseChargedPrice || getRetSuitcaseCharged(inx)">{{ $t('check-booking.suitcase-included') }}</span>
                <span v-else>{{ $t('check-booking.suitcase-not-included') }}</span>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';
import { mapState } from 'vuex';

export default {
  mixins: [imageUrlMixin],
  props: {
    flights: {
      type: Object,
      default: null,
    },
    userList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      chargedBaggageData: (state) => state.luggagePriceStore.chargedBaggage,
    }),
    outward() {
      return this.flights.outward;
    },
    departureFlightData() {
      return this.chargedBaggageData.filter((data) => !data.returningLeg);
    },
    inward() {
      return this.flights.inward;
    },
    returnFlightData() {
      return this.chargedBaggageData.filter((data) => data.returningLeg);
    },
    chargedBaggagePrices() {
      return window.sessionStorage.getItem('baggagePrices') ? JSON.parse(window.sessionStorage.getItem('baggagePrices')) : [];
    },
    depTrolleyChargedPrice() {
      return this.chargedBaggagePrices.find((price) => !price.returningLeg)?.carryOnBaggage || null;
    },
    depSuitcaseChargedPrice() {
      return this.chargedBaggagePrices.find((price) => !price.returningLeg)?.checkinBaggage || null;
    },
    retTrolleyChargedPrice() {
      return this.chargedBaggagePrices.find((price) => price.returningLeg)?.carryOnBaggage || null;
    },
    retSuitcaseChargedPrice() {
      return this.chargedBaggagePrices.find((price) => price.returningLeg)?.checkinBaggage || null;
    },
  },
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch('RESTORE_LUGGAGE_SELECTION_FROM_STORAGE');
  },
  methods: {
    getDepTrolleyCharged(userId) {
      return this.departureFlightData.find((p) => p.passengerRefId === String(userId + 1))?.carryOnBaggage?.charge || false;
    },
    getDepSuitcaseCharged(userId) {
      return this.departureFlightData.find((p) => p.passengerRefId === String(userId + 1))?.checkinBaggage?.charge || false;
    },
    getRetTrolleyCharged(userId) {
      return this.returnFlightData.find((p) => p.passengerRefId === String(userId + 1))?.carryOnBaggage?.charge || false;
    },
    getRetSuitcaseCharged(userId) {
      return this.returnFlightData.find((p) => p.passengerRefId === String(userId + 1))?.checkinBaggage?.charge || false;
    },
  },
};
</script>

<style scoped>
.include-ok {
  color: green;
}
.include-not {
  color: red;
}
.one-passenger-box {
  border: 1px solid gray;
  border-radius: 10px;
}
.hotel_details_body .one-passenger-box .icon {
  color: #0C68AE;
}
.after_booking_area .hotel_details_area {
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}

.hotel_details_area .hotel_details_heading {
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(39, 136, 210, 1) 0%,
      rgba(1, 98, 172, 1) 100%);
  padding: 15px 20px;
}

.hotel_details_heading h3 {
  margin: 0 0;
  padding: 0 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.hotel_details_body {
  padding: 35px 20px;
  border: 1px solid #949494;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 25px;
}

.hotel_details_body .flight_box_div {
  padding: 0px 15px;
}

.hotel_details_body .flight_box_div ul {
  padding: 0 0;
  margin: 0 0;
  list-style: none;
}

.hotel_details_body .flight_box_div ul li {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.hotel_details_body .flight_box_div ul li .icon {
  margin-left: 10px;
}

.hotel_details_body .flight_box_div.flight {
  margin: auto;
  text-align: center;
}

.hotel_details_body .flight_box_div.flight img {
  width: 175px;
}

.mergeLine {
  margin-top: -10px;
}

@media (max-width: 1200px) {
  .hotel_details_body .flight_box_div.flight img {
    width: 70px;
  }

  .hotel_details_body .flight_box_div {
    padding: 0px 5px;
  }
}

@media (max-width: 479px) {
  .hotel_details_area .hotel_details_heading h3 {
    font-size: 20px;
  }

  /* .hotel_details_body .d-flex {
    display: block !important;
  } */

  .hotel_details_body {
    padding: 35px 0px;
  }

  .hotel_details_body .flight_box_div.flight {
    margin-bottom: 15px;
  }

  .hotel_details_body .flight_box_div.flight img {
    width: 125px;
  }
}
</style>
