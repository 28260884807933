<template>
  <b-container fluid class="maccabi-selection p-4 bg-dark" :class="`maccabi-count-${destinations.length}`">

    <b-row class="desktop-row" v-if="isCategoryLinkAgency">
      <b-col v-for="(dest, inx) in destinations" :key="inx" class="one-thumb">
        <router-link :to="`${dest.url}`">
          <b-img class="dest-image" thumbnail fluid :src="dest.images[1]" rounded alt="Image 1"></b-img>

          <p class="text title w-100 text-center p-0 m-0" v-if="dest.category !== 'vacation_pack'">
            {{ dest.data.hotelName[lang] }}
          </p>
          <p class="text sub-title w-100 text-center p-0 m-0" v-if="dest.category !== 'vacation_pack'">
            {{ dest.data.destinationName[lang] }}, {{ dest.data.destinationCountryName[lang] || '' }}
          </p>

          <ul class="description pt-4 px-2 w-100" style="left:0" v-else>
            <li class="w-100 text-center">{{ dest.data.hotelName[lang] }}</li>
            <li class="pr-5">
              <b-form-rating class="ui rating m-auto bg-transparent" variant="warning" inline
                :value="dest.data.stars" readonly locale="he"></b-form-rating>
            </li>
            <li class="pr-5 desc-item">
              <span class="icon ml-1"><i class="fa-solid fa-bell-concierge"></i></span>
              {{ dest.data.boardBasisName[lang] }}
            </li>
            <li class="pr-5 desc-item">
              <span class="icon ml-1"><i class="fa-solid fa-truck-plane"></i></span>
              {{ dest.data.transfers }}
            </li>
            <li class="pr-5 desc-item">
              <span class="icon ml-3"><i class="fas fa-suitcase-rolling"></i></span>
              <span class="weight-number">{{ dest.data.roundTripFlight?.FlightDetail?.[0].FL_Max_Weight || 0 }}Kg</span>
            </li>
            <li class="pr-5 desc-item">
              <span class="icon ml-3"><i class="fas fa-briefcase"></i></span>
              <span class="weight-number">{{ dest.data.roundTripFlight?.FlightDetail?.[0].FL_Max_Weight_Trolley || 0 }}Kg</span>
            </li>
          </ul>
          <b-button class="order-button" size="sm" variant="primary">{{ $t('home.book-now.book-now') }}</b-button>
        </router-link>
      </b-col>
    </b-row>

    <b-row class="desktop-row" v-else>
      <b-col v-for="(dest, inx) in destinations" :key="inx" class="one-thumb">
        <router-link :to="`${dest.url}`">
          <b-img class="dest-image" thumbnail fluid :src="dest.images[0]" rounded alt="Image 1"></b-img>
          <p class="text top-title w-100 text-right p-0 mr-4" v-if="is1MaccabiFCLSHAVAgency">
            {{ $t('login.women-day') }}
          </p>
          <p class="text">
            <span>{{ (dest.dest === 'BDV') ? 'מונטנגרו' : ( (dest.dest === 'BUH') ? 'רומניה'  : `${dest.name[lang]}`) }}</span>
          </p>
          <b-button class="order-button" size="sm" variant="primary">{{ $t('home.book-now.book-now') }}</b-button>
        </router-link>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg, BButton, BFormRating } from 'bootstrap-vue';
import { mapGetters, mapState } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'maccabi-deal ',
  mixins: [imageUrlMixin],
  props: {
    deals: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BContainer, BRow, BCol, BImg, BButton, BFormRating,
  },
  computed: {
    ...mapState({
      isCategoryLinkAgency: (state) => state.agencyUsers.isCategoryLinkAgency,
      categories: (state) => state.categories,
    }),
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      is1MaccabiFCLSHAVAgency: 'GET_IS_1_MACCABI_AGENCY',
    }),
    destinations() {
      const { deals, isCategoryLinkAgency } = this;
      const dets = deals.map((deal) => ({
        dest: deal.destinationCode,
        images: isCategoryLinkAgency && deal.categoryId === 'vacation_pack' ? deal.galleryUrl : deal.destImageUrls,
        url: deal.selectedPackageUrl,
        name: deal.destinationName,
        category: deal.categoryId,
        data: deal,
      }));
      const uniqueDestList = isCategoryLinkAgency

        ? dets.reduce((acc, current) => {
          const duplicate = acc.find((item) => item.category === current.category);
          if (!duplicate) {
            acc.push(current);
          }
          return acc;
        }, [])

        : dets.reduce((acc, current) => {
          const duplicate = acc.find((item) => item.dest === current.dest);
          if (!duplicate) {
            acc.push(current);
          }
          return acc;
        }, []);
      return uniqueDestList;
    },
  },
  methods: {
    categoryName(code) {
      const { categories, lang } = this;
      return categories.find((cate) => cate.code === code)?.name[lang];
    },
  },
};
</script>

<style scoped>
  .maccabi-count-3 {
    width: 70%;
  }
  .maccabi-count-2 {
    width: 50%;
  }
  .maccabi-count-1 {
    width: 30%;
  }
  .maccabi-selection .dest-image {
    width: 100%;
    height: 100%;
  }

  .maccabi-selection .one-thumb {
    position: relative;
  }
  .maccabi-selection .one-thumb .text,
  .maccabi-selection .one-thumb .description {
    position: absolute;
    text-shadow: 1px 2px 5px black;
    color: white;
  }
  .maccabi-selection .one-thumb .description .desc-item {
    font-weight: 900;
  }
  .maccabi-selection .one-thumb .description {
    top: 0;
    list-style: none;
    font-size: 1rem;
  }
  .maccabi-selection .one-thumb .description .icon {
    font-size: 1.2rem;
  }
  .maccabi-selection .one-thumb .description li * {
    border: none;
  }
  .maccabi-selection .one-thumb .text {
    font-size: 3rem;
    font-weight: 800;
    bottom: 0;
    padding-right: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .maccabi-selection .one-thumb .text.category-only {
    font-size: 1.5rem;
    top: 0;
    bottom: auto;
  }
  .maccabi-selection .one-thumb .text.title,
  .maccabi-selection .one-thumb .text.sub-title {
    bottom: 0;
    left: 0;
  }
  .maccabi-selection .one-thumb .text.top-title {
    top: 0;
  }
  .maccabi-selection .one-thumb .text.title {
    font-size: 1.8rem;
    height: 70%;
  }
  .maccabi-selection .one-thumb .text.sub-title {
    font-size: 1.3rem;
    height: 55%;
  }
  .maccabi-selection .one-thumb .order-button{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width:479px){
    .maccabi-selection {
      width: 100%;
    }
    .maccabi-selection .one-thumb .text{
      padding-right: 10px;
      font-size: 1.5rem;
    }
    .maccabi-selection .one-thumb{
      margin-bottom: 10px;
    }
    .maccabi-selection .desktop-row{
      display: block;
    }
  }
</style>
