<template>
  <div class="passenger_details_main_area mt-3">
    <div class="passenger_header m-0 py-3">
      <div class="d-flex justify-content-between align-items-baseline">
        <h3 class="m-0">
          <span class="icon ml-2">
            <i class="fas fa-suitcase"></i>
          </span>
          <span>{{ $t('check-booking.baggage') }}</span>
        </h3>
        <h4 class="m-0">
          <strong v-if="isAllIncludedPrice">{{ $t('booking.all-include-in-price') }}</strong>
          <strong v-else>{{ $t('booking.total-extra', {price: `${getPriceWithSymbol(currency, totalAmount)}`}) }}</strong>
        </h4>
      </div>
    </div>

    <div class="passenger_body row m-0" v-if="device==='desktop'">
      <div class="col-lg-4 col-12 luggage-selection" v-for="(user, inx) in excludedInfantsList" :key="inx">
        <LuggageOneUserSelection :data="user" :inx="inx" :priceData="priceData" :flightData="flights"/>
      </div>
    </div>

    <div class="passenger_body p-0"  v-else>
      <div class="card" no-body v-for="(user, inx) in excludedInfantsList" :key="inx">
        <b-card-header header-tag="header" role="tab">
          <b-button block v-b-toggle="`accordion-${inx}`" variant="primary" class="btn-header-link">
            <strong v-if="user.firstName || user.lastName">{{ $t('booking.passenger') }} {{ inx + 1 }} - {{ `${user.firstName} ${user.lastName}` }}</strong>
            <strong v-else>{{ $t('booking.passenger') }} {{ inx + 1 }}</strong>
          </b-button>
        </b-card-header>
        <b-collapse :id="`accordion-${inx}`" :visible="inx===0" accordion="my-accordion">
          <b-card>
            <LuggageOneUserSelection :data="user" :inx="inx" :priceData="priceData" :flightData="flights"/>
          </b-card>
        </b-collapse>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import {
  BButton,
  BCardHeader,
  BCard,
  BCollapse, VBToggle,
} from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BCardHeader,
    BCollapse,
    BCard,
    LuggageOneUserSelection: () => import('@/components/booking/atoms/LuggageOneUserSelection'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [gMixin],
  props: {
    userList: {
      type: Array,
      default: () => [],
    },
    priceData: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: 'USD',
    },
    flights: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      totalAmount: 'GET_LUGGAGE_AMOUNT',
    }),
    excludedInfantsList() {
      return this.userList.filter((user) => user.generation !== 'infant');
    },
    isAllIncludedPrice() {
      const { priceData } = this;
      return !priceData.some((price) => price.carryOnBaggage !== null || price.checkinBaggage !== null);
    },
  },
  watch: {
  },
  created() {
  },
  methods: {

  },
};

</script>

<style scoped>
.passenger_body .card, .passenger_body .card .card-header {
  background-color: transparent;
  border: none;
}
header button.btn {
  text-align: inherit;
}
.card .card-header .btn-header-link strong {
  font-size: 1.5rem;
}
.card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
.card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
  margin-top: 5px;
}
</style>
