<template>
  <div class="my-3 one-flight-wrapper p-3">
    <h5 class="m-0">
      <strong>
        <span v-if="returnDepartureType==='return'">{{ $t('flight-only.round-trip-return') }}</span>
        <span v-else>{{ $t('flight-only.round-trip-departure') }}</span>
      </strong>
    </h5>

    <div class="d-flex my-3 justify-content-between">
      <div class="d-flex col-lg-7 col-7 p-0 m-0">
        <span class="icon ml-2">
          <i class="fas fa-shopping-bag"></i>
        </span>
        <div>
          <!-- <h5 class="m-0">{{ $t('booking.adding-carry-on-bag') }}</h5> -->
          <div>{{ $t('booking.handbag-cost-included') }}</div>
        </div>
      </div>
      <div v-if="flight" class="align-content-center">{{ flight.FL_Max_Weight_Hand }} {{ $t('booking.kg') }}</div>
      <ToggleSwitch :toggled="true" :disabled="true" name="" title=""/>
    </div>

    <div class="d-flex my-4 justify-content-between">
      <div class="d-flex col-lg-7 col-7 p-0 m-0">
        <span class="icon ml-2">
          <i class="fas fa-suitcase-rolling"></i>
        </span>
        <div>
          <h5 class="m-0" v-if="!isIncludedTrolleyPrice">{{ $t('booking.adding-trolley') }}</h5>
          <div v-if="isIncludedTrolleyPrice">{{ $t('booking.trolley-cost-included') }}</div>
          <div v-else>{{ $t('booking.adding-trolley-cost', {cost: `$${trolleyPriceFromServer}`}) }}</div>
        </div>
      </div>
      <div v-if="flight" class="align-content-center">{{ flight.FL_Max_Weight_Trolley }} {{ $t('booking.kg') }}</div>
      <ToggleSwitch :toggled="isIncludedTrolleyPrice" :disabled="isIncludedTrolleyPrice" name="" title="" @toggle="doToggleTrolley"/>
    </div>

    <div class="d-flex mt-3 justify-content-between">
      <div class="d-flex col-lg-7 col-7 p-0 m-0">
        <span class="icon ml-2">
          <i class="fas fa-suitcase"></i>
        </span>
        <div>
          <h5 class="m-0" v-if="!isIncludedSuitcasePrice">{{ $t('booking.adding-suitcase') }}</h5>
          <div v-if="isIncludedSuitcasePrice">{{ $t('booking.suitcase-included-in-price') }}</div>
          <div v-else>{{ $t('booking.adding-suitcase', {cost: `$${suitcasePriceFromServer}`}) }}</div>
        </div>
      </div>
      <div v-if="flight" class="align-content-center">{{ flight.FL_Max_Weight }} {{ $t('booking.kg') }}</div>
      <ToggleSwitch :toggled="isIncludedSuitcasePrice" :disabled="isIncludedSuitcasePrice" name="" title="" @toggle="doToggleSuitcase"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'luggage-one-direction-selection',
  components: {
    ToggleSwitch: () => import('@/components/booking/atoms/ToggleSwitch'),
  },
  props: {
    returnDepartureType: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    flight: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      trolleyPrice: 0,
      suitcasePrice: 0,
    };
  },
  computed: {
    trolleyPriceFromServer() {
      return this.data?.carryOnBaggage;
    },
    suitcasePriceFromServer() {
      return this.data?.checkinBaggage;
    },
    isIncludedTrolleyPrice() {
      return this.trolleyPriceFromServer === null;
    },
    isIncludedSuitcasePrice() {
      return this.suitcasePriceFromServer === null;
    },
  },
  methods: {
    doToggleTrolley(toggled) {
      if (this.isIncludedTrolleyPrice) return;
      if (toggled) {
        this.trolleyPrice = this.trolleyPriceFromServer;
      } else {
        this.trolleyPrice = 0;
      }
      this.$emit('setChargePrice', { trolley: this.trolleyPrice, suitcase: this.suitcasePrice });
    },
    doToggleSuitcase(toggled) {
      if (this.isIncludedSuitcasePrice) return;
      if (toggled) {
        this.suitcasePrice = this.suitcasePriceFromServer;
      } else {
        this.suitcasePrice = 0;
      }
      this.$emit('setChargePrice', { trolley: this.trolleyPrice, suitcase: this.suitcasePrice });
    },
  },
};

</script>

<style scoped>
.one-flight-wrapper {
  border: 1px white solid;
  border-radius: 10px;
}
</style>
