// import { get, post, utf8ToB64, b64ToUtf8 } from '@/utils/api';

export default {
  state: () => ({
    chargedBaggage: [],
  }),
  getters: {
    GET_LUGGAGE_AMOUNT: (state) => {
      const chargeArray = state.chargedBaggage.map((one) => [one.carryOnBaggage?.charge || 0, one.checkinBaggage?.charge || 0]).flat();
      return chargeArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
  },
  mutations: {
    INIT_CHARGED_BAGGAGE_DATA: (state, payload) => {
      const originData = state.chargedBaggage;
      const { passengerRefId } = payload;
      const oneData = [
        {
          passengerRefId,
          returningLeg: false,
          carryOnBaggage: null,
          checkinBaggage: null,
        },
        {
          passengerRefId,
          returningLeg: true,
          carryOnBaggage: null,
          checkinBaggage: null,
        },
      ];
      const restData = originData.filter((chargedData) => chargedData.passengerRefId !== passengerRefId);

      state.chargedBaggage = restData.concat(...oneData);
    },
    SET_CHARGED_BAGGAGE_DATA: async (state, payload) => {
      const originalData = state.chargedBaggage;

      const {
        passengerRefId,
        returningLeg,
        trolleyCharge,
        suitcaseCharge,
      } = payload;
      const oneData = {
        passengerRefId,
        returningLeg,
        carryOnBaggage: trolleyCharge ? {
          byPieces: true,
          pieceCount: 1,
          weight: null,
          unit: null,
          charge: trolleyCharge,
        } : null,
        checkinBaggage: suitcaseCharge ? {
          byPieces: true,
          pieceCount: 1,
          weight: null,
          unit: null,
          charge: suitcaseCharge,
        } : null,
      };
      const restData = originalData.filter((chargedData) => chargedData.passengerRefId !== passengerRefId || chargedData.returningLeg !== returningLeg);

      state.chargedBaggage = restData.concat(oneData);
    },
    SET_LUGGAGE_SELECTION_DATA: (state, payload) => {
      state.chargedBaggage = payload;
    },
  },
  actions: {
    STORE_LUGGAGE_SELECTION_TO_STORAGE: (context) => {
      window.sessionStorage.setItem('usrChosenBaggagePrices', JSON.stringify(context.state.chargedBaggage));
    },
    RESTORE_LUGGAGE_SELECTION_FROM_STORAGE: (context) => {
      const storedData = window.sessionStorage.getItem('usrChosenBaggagePrices') ? JSON.parse(window.sessionStorage.getItem('usrChosenBaggagePrices')) : [];
      context.commit('SET_LUGGAGE_SELECTION_DATA', storedData);
    },
  },
};
