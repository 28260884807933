import { get, post, utf8ToB64, b64ToUtf8 } from '@/utils/api';

export default {
  state: () => ({
    homeLink: '/',
    agencyAuthorized: false,
    isAgencyAuthoring: false,
    agencyUser: null,
    agencyUserPhoneNumber: null,
    userAgencySid: null,
    agencyUserVerifyCode: null,
    agencyErrorMessage: null,
    isAgencyLoginState: false,

    // marketer part. Moved from main store.
    marketerId: '',
    marketerAgencyName: '',
    marketerAgencyContent: null,
    bypassPayment: false,
    subAgency: '',

    isLoginMarketer: false,
    preferSmsToPassword: false,
    forPricePlanOnly: false,
    enableSearchAgent: false,
    isFcAgentMarketerMode: false,

    isMultipleCoupon: false,
    isMarketerSite: false,

    // Odyssey agent part
    odysseyAgentState: false,
    odysseyAgentContent: null,
    odysseyAgentClerkName: '',
    selectedOdysseyAgentCode: null,
    loginOdysseyAgentCode: null,

    allAgentList: [],
    agentListForPricePlanOnly: [],

    // MACCABI agency part
    isMaccabiAgency: false,
    is1MaccabiFCLSHAVAgency: false,
    isFCLSHAVAgency: false,
    isTargetAgency: false,
    isMenoraAgency: false,
    isFCLTESHAgency: false,
    isFCLMEITAgency: false,
    isByteAgency: false,
    changedDateStateForMaccabi: false,
    isMaccabiFull: false,
    isFCINTSPAgency: false,
    optionsMaccabi: {
      availDeadline: false,
      availSplitCard: false,
    },
    isCategoryLinkAgency: false,

    isDisableHomeLink: false,
    isRemoveSocialIcon: false,
    isRemoveHomeButton: false,
    isRemovePhoneButton: false,
  }),
  getters: {
    GET_HOME_LINK: (state) => state.homeLink,
    IS_AGENCY_AUTHORIZED: (state) => state.agencyAuthorized, // internal odyssey agent or agency login state
    GET_AGENCY_ERROR_MESSAGE: (state) => state.agencyErrorMessage,
    GET_IS_AGENCY_AUTHORING: (state) => state.isAgencyAuthoring,
    GET_AGENCY_SID: (state) => state.userAgencySid,
    GET_AGENCY_USER: (state) => state.agencyUser,
    GET_AGENCY_LOGIN_STATE: (state) => state.isAgencyLoginState,

    // marketer part. Moved from main store.
    GET_MARKETER_ID: (state) => state.marketerId,
    GET_MARKETER_AGENCY: (state) => state.marketerAgencyName,
    GET_MARKETER_AGENCY_CONTENT: (state) => state.marketerAgencyContent,
    GET_LIMIT_DEALS_STATE: (state) => state.limitToSpecificDeals, // limitToSpecificDeals of internal odyssey agent
    GET_BYPASS_PAYMENT_STATE: (state) => state.bypassPayment, // BEZEQ marketer or bypass payment of internal odyssey agent
    GET_SUB_AGENCY: (state) => state.subAgency,

    GET_IS_MULTIPLE_COUPON: (state) => state.isMultipleCoupon,
    GET_IS_MARKETER_SITE: (state) => state.isMarketerSite,

    GET_IS_LOGIN_MARKETER: (state) => state.isLoginMarketer,
    GET_PREFER_SMS_PASS: (state) => state.preferSmsToPassword, // preferSmsToPass is true, sms based login, else pass-based one.
    GET_FOR_PRICE_PLAN_ONLY: (state) => state.forPricePlanOnly,
    GET_ENABLE_SEARCH_AGENT_STATE: (state) => state.enableSearchAgent,
    GET_FC_AGENT_MARKETER_MODE: (state) => state.isFcAgentMarketerMode,

    // Odyssey agent part
    GET_ODYSSEY_AGENT_STATE: (state) => state.odysseyAgentState, // internal odyssey agent part
    GET_ODYSSEY_AGENT_CONTENT: (state) => state.odysseyAgentContent,
    GET_ODYSSEY_AGENT_CLERK_NAME: (state) => state.odysseyAgentClerkName,
    GET_SELECTED_ODYSSEY_AGENT_CODE: (state) => state.selectedOdysseyAgentCode,
    GET_LOGIN_ODYSSEY_AGENT_CODE: (state) => state.loginOdysseyAgentCode,

    GET_ALL_AGENT_LIST: (state) => state.allAgentList,
    GET_AGENT_LIST_PRICE_PLAN_ONLY: (state) => state.agentListForPricePlanOnly,

    // MACCABI agency part
    GET_IS_MACCABI_AGENCY: (state) => state.isMaccabiAgency,
    GET_IS_1_MACCABI_AGENCY: (state) => state.is1MaccabiFCLSHAVAgency,
    GET_IS_FCLSHAV_AGENCY: (state) => state.isFCLSHAVAgency,
    GET_IS_TARGET_AGENCY: (state) => state.isTargetAgency,
    GET_IS_MENORA_AGENCY: (state) => state.isMenoraAgency,
    GET_IS_FCLTESH_AGENCY: (state) => state.isFCLTESHAgency,
    GET_IS_FCLMEIT_AGENCY: (state) => state.isFCLMEITAgency,
    GET_IS_BYTE_AGENCY: (state) => state.isByteAgency,
    GET_CHANGED_DATE_STATE_FOR_MACCABI_AGENCY: (state) => state.changedDateStateForMaccabi,
    GET_IS_MACCABI_FULL: (state) => state.isMaccabiFull,
    GET_IS_FCINTSP_AGENCY: (state) => state.isFCINTSPAgency,
    GET_OPTIONS_MACCABI: (state) => state.optionsMaccabi,

    GET_IS_DISABLE_HOME_LINK: (state) => state.isDisableHomeLink,
    GET_IS_REMOVE_SOCIAL_ICON: (state) => state.isRemoveSocialIcon,
    GET_IS_REMOVE_HOME_BUTTON: (state) => state.isRemoveHomeButton,
    GET_IS_REMOVE_PHONE_BUTTON: (state) => state.isRemovePhoneButton,
  },
  mutations: {
    SET_HOME_LINK: (state, payload) => {
      const marketerParam = payload.marketerId ? `?marketerId=${payload.marketerId}` : '';
      const fcAgentModeParam = payload['fc-agent-mode'] !== undefined ? '&fc-agent-mode' : '';
      const secretParam = payload.secret ? `&secret=${payload.secret}` : '';
      // eslint-disable-next-line no-nested-ternary
      state.homeLink = state.isDisableHomeLink ? '#' : state.isFCINTSPAgency ? `/${fcAgentModeParam}${secretParam}` : `/${marketerParam}${fcAgentModeParam}${secretParam}`;
    },
    SET_AGENCY_AUTHORIZED: (state, payload) => {
      state.agencyAuthorized = payload;
    },
    SET_AGENCY_ERROR_MESSAGE: (state, payload) => {
      state.agencyErrorMessage = payload;
    },
    SET_AGENCY_PHONE_NUMBER: (state, payload) => {
      state.agencyUserPhoneNumber = payload;
    },
    SET_AGENCY_SID: (state, payload) => {
      state.userAgencySid = payload;
    },
    SET_AGENCY_USER: (state, payload) => {
      state.agencyUser = payload;
    },
    SET_AGENCY_VERIFY_CODE: (state, payload) => {
      state.agencyUserVerifyCode = payload;
    },
    SET_IS_AGENCY_AUTHORING: (state, payload) => {
      state.isAgencyAuthoring = payload;
    },
    SET_AGENCY_LOGIN_STATE: (state, payload) => {
      state.isAgencyLoginState = payload;
    },

    // marketer part. Moved from main store.
    SET_MARKETER_ID: (state, payload) => {
      state.marketerId = payload === undefined ? '' : payload;
    },
    SET_AGENCY_FROM_MARKETER_ID: (state, payload) => {
      state.marketerAgencyName = payload === undefined ? '' : payload;
    },
    SET_AGENCY_CONTENT_MARKETER_ID: (state, payload) => {
      state.marketerAgencyContent = payload || null;
      state.bypassPayment = payload?.bypassPayment || false;
      state.limitToSpecificDeals = payload?.limitToSpecificDeals || false;

      state.isLoginMarketer = payload?.isLoginMarketer || false;
      state.forPricePlanOnly = payload?.forPricePlanOnly || false;
      state.preferSmsToPassword = payload?.preferSmsToPassword || false;
      state.enableSearchAgent = payload?.enableSearchAgent || false;

      state.isDisableHomeLink = payload.marketerSpecificContents?.componentsVisibility?.disableHomeLink || false;
      state.isRemoveSocialIcon = payload.marketerSpecificContents?.componentsVisibility?.removeSocialIcon || false;
      state.isRemoveHomeButton = payload.marketerSpecificContents?.componentsVisibility?.removeHomeButton || false;
      state.isRemovePhoneButton = payload.marketerSpecificContents?.componentsVisibility?.removePhoneButton || false;

      state.is1MaccabiFCLSHAVAgency = payload?.odyAgentCode === '1MACCAB' || payload?.odyAgentCode === 'FCLMEIT' || payload?.odyAgentCode === 'FCLSHAV' || false;
      state.isFCLSHAVAgency = payload?.odyAgentCode === 'FCLSHAV' || false;
      state.isMenoraAgency = payload?.odyAgentCode === 'MENORA' || payload?.odyAgentCode === 'FCLAYAL' || payload?.odyAgentCode === 'FCLTESH' || false;
      state.isFCLMEITAgency = payload?.odyAgentCode === 'FCLMEIT' || false;

      state.isMaccabiAgency = payload?.odyAgentCode === 'MACCABI' || payload?.odyAgentCode === 'SDABACH'
        || state.is1MaccabiFCLSHAVAgency || state.isMenoraAgency || state.isFCLMEITAgency || false;

      state.isCategoryLinkAgency = payload?.odyAgentCode === 'SDABACH' || false;

      state.isFCLTESHAgency = payload?.odyAgentCode === 'FCLTESH';

      state.isTargetAgency = payload?.odyAgentCode === 'TARGET' || false;
      state.isByteAgency = payload?.odyAgentCode === 'BYTE' || false;
      switch (payload?.odyAgentCode) {
        case 'MACCABI':
          state.optionsMaccabi = {
            availDeadline: true,
            availSplitCard: true,
          };
          break;
        case '1MACCAB':
          state.optionsMaccabi = {
            availSplitCard: true,
          };
          break;
        case 'MENORA':
          state.optionsMaccabi = {
            availSplitCard: true,
          };
          break;
        case 'FCLTESH':
          state.optionsMaccabi = {
            availSplitCard: true,
          };
          break;
        case 'FCLAYAL':
          state.optionsMaccabi = {
            availSplitCard: true,
          };
          break;
        case 'FCLMEIT':
          state.optionsMaccabi = {
            availSplitCard: true,
          };
          break;
        case 'FCLSHAV':
          state.optionsMaccabi = {
            availSplitCard: true,
          };
          break;
        case 'TRATOGE':
          state.optionsMaccabi = {
            availDeadline: false,
            availSplitCard: true,
          };
          break;
        default:
      }
      state.isFCINTSPAgency = payload?.odyAgentCode === 'FCINTSP' || false;
    },
    SET_ODYSSEY_BYPASS_PAYMENT: (state, payload) => {
      state.bypassPayment = payload;
    },
    SET_SUB_AGENCY: (state, payload) => {
      state.subAgency = payload === undefined ? '' : payload;
    },
    SET_FC_AGENT_MARKETER_MODE: (state, payload) => {
      state.isFcAgentMarketerMode = payload;
    },

    SET_IS_MULTIPLE_COUPON: (state, payload) => {
      state.isMultipleCoupon = payload;
    },
    SET_IS_MARKETER_SITE: (state, payload) => {
      state.isMarketerSite = payload;
    },

    // Odyssey agent part
    SET_ODYSSEY_AGENT_CONTENT: (state, payload) => {
      state.odysseyAgentState = payload.forInternalAgentSite;
      state.odysseyAgentContent = payload;
    },
    SET_ODYSSEY_AGENT_CLERK_NAME: (state, payload) => {
      state.odysseyAgentClerkName = payload;
    },
    SET_SELECTED_ODYSSEY_AGENT_CODE: (state, payload) => {
      state.selectedOdysseyAgentCode = payload;
    },
    SET_LOGIN_ODYSSEY_AGENT_CODE: (state, payload) => {
      state.loginOdysseyAgentCode = payload;
    },
    SET_ALL_AGENT_LIST: (state, payload) => {
      state.allAgentList = payload;
    },
    SET_AGENT_LIST_PRICE_PLAN_ONLY: (state, payload) => {
      state.agentListForPricePlanOnly = payload.filter((d) => d.odyCode.toUpperCase() !== 'TESTTEST');
    },
    SET_CHANGED_DATE_FOR_MACCABI_AGENCY_PAGE: (state, payload) => {
      state.changedDateStateForMaccabi = payload;
    },
    SET_IS_MACCABI_FULL: (state, payload) => {
      state.isMaccabiFull = payload;
    },
  },
  actions: {
    REQUEST_AGENCY_LOGIN: async (context, payload) => {
      const data = new FormData();
      const { marketerId, odysseyAgentState, odysseyAgentContent, isFcAgentMarketerMode } = context.state;

      if (odysseyAgentState && !isFcAgentMarketerMode) {
        data.append('odyAgentCode', payload.odyAgentCode);
        data.append('clerkName', payload.clerkName);
        data.append('password', payload.password);
      } else if (isFcAgentMarketerMode) {
        data.append('clerkName', payload.clerkName);
        data.append('password', payload.password);
        data.append('agencyCode', marketerId);
      } else {
        data.append('agencyCode', marketerId);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', true);
      const requestUrl = (odysseyAgentState || isFcAgentMarketerMode) ? '/agent/login' : '/agency/requestLogin';

      try {
        const response = await post('BFF', requestUrl, data, context, '', true);
        if (response.data.error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_SID', response.data.data.agencySid);

          if (odysseyAgentState || isFcAgentMarketerMode) {
            context.commit('SET_AGENCY_LOGIN_STATE', false);
            context.commit('SET_AGENCY_ERROR_MESSAGE', null);
            context.commit('SET_AGENCY_AUTHORIZED', true);

            if (isFcAgentMarketerMode) {
              window.localStorage.setItem('fcAgentAgencySid', context.state.userAgencySid);
            } else {
              window.localStorage.setItem('agencySid', context.state.userAgencySid);
            }
            window.localStorage.setItem('agencyLogin', true);
            if (!isFcAgentMarketerMode) window.localStorage.setItem('loginAgencyCode', payload.odyAgentCode.toUpperCase());
            window.localStorage.setItem('agencyUser', utf8ToB64(JSON.stringify(odysseyAgentContent)));
          }
        }
      } catch (error) {
        context.commit('SET_AGENCY_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', false);
    },
    REQUEST_AGENCY_BY_VERIFY_CODE: async (context) => {
      const data = new FormData();
      const { agencyUserVerifyCode, marketerAgencyContent } = context.state;
      data.append('verificationCode', agencyUserVerifyCode);
      context.commit('SET_IS_AGENCY_AUTHORING', true);
      try {
        const response = await post('BFF', '/agency/verifyCode', data, context, '', true);
        if (response.data.error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
          context.commit('SET_AGENCY_SID', null);
        } else {
          context.commit('SET_AGENCY_LOGIN_STATE', false);
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_AUTHORIZED', true);
          context.commit('SET_AGENCY_USER', marketerAgencyContent);

          window.localStorage.setItem('agencySid', context.state.userAgencySid);
          window.localStorage.setItem('agencyLogin', true);
          window.localStorage.setItem('agencyUser', utf8ToB64(JSON.stringify(marketerAgencyContent)));
        }
      } catch (error) {
        context.commit('SET_AGENCY_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', false);
    },
    REQUEST_AGENCY_LOGOUT: async (context) => {
      const { marketerId, odysseyAgentState, isFcAgentMarketerMode } = context.state;

      context.commit('SET_IS_AGENCY_AUTHORING', true);
      const requestUrl = (odysseyAgentState || isFcAgentMarketerMode) ? '/agent/logout' : `/agency/logout?agency=${marketerId}`;

      try {
        const response = await post('BFF', requestUrl, null, context);
        if (response.data.error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
        } else {
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_AUTHORIZED', false);
          context.commit('SET_AGENCY_SID', null);
          context.commit('SET_AGENCY_USER', null);

          context.commit('SET_AGENCY_LOGIN_STATE', true);

          window.localStorage.removeItem('agencySid');
          window.localStorage.removeItem('agencyLogin');
          window.localStorage.removeItem('agencyUser');
        }
      } catch (error) {
        context.commit('SET_AGENCY_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', false);
    },
    VERIFY_AGENCY_LOGIN_STATE: async (context) => {
      const { marketerId, odysseyAgentState, isFcAgentMarketerMode } = context.state;
      const agencySid = isFcAgentMarketerMode ? window.localStorage.getItem('fcAgentAgencySid') : window.localStorage.getItem('agencySid');
      const requestUrl = (odysseyAgentState || isFcAgentMarketerMode) ? '/agent/checkSession' : `/agency/checkSession?agency=${marketerId}`;

      if (agencySid) {
        await context.commit('SET_AGENCY_SID', agencySid);
        try {
          const response = await get('BFF', requestUrl, context);

          if (response.data.error) {
            context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
          } else if (response.data.data.logined) {
            context.commit('SET_AGENCY_LOGIN_STATE', false);
            context.commit('SET_ODYSSEY_AGENT_CLERK_NAME', response.data.data.clerkName);
            context.commit('SET_AGENCY_AUTHORIZED', true);
            context.commit('SET_SELECTED_ODYSSEY_AGENT_CODE', window.localStorage.getItem('loginAgencyCode'));
            context.commit('SET_LOGIN_ODYSSEY_AGENT_CODE', window.localStorage.getItem('loginAgencyCode'));
            context.commit('SET_AGENCY_USER', JSON.parse(b64ToUtf8(window.localStorage.getItem('agencyUser'))));
          }
        } catch (error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_AUTHORIZED', false);
          context.commit('SET_AGENCY_SID', null);
          context.commit('SET_AGENCY_USER', null);

          window.localStorage.removeItem('agencySid');
          window.localStorage.removeItem('agencyLogin');
          window.localStorage.removeItem('agencyUser');
          context.commit('SET_AGENCY_LOGIN_STATE', true);
        }
      } else if (odysseyAgentState) {
        context.commit('SET_AGENCY_LOGIN_STATE', true);
      }
    },

    FETCH_IS_MARKETER_SITE: (context) => {
      const { marketerId, odysseyAgentContent, marketerAgencyContent } = context.state;
      const isMainOrWhiteLabel = odysseyAgentContent?.forMainSite || odysseyAgentContent?.forWhiteLabelSite;
      let result = false;
      if (isMainOrWhiteLabel) {
        if (marketerId) {
          result = marketerAgencyContent.forMarketer;
        } else {
          result = odysseyAgentContent.forMarketer;
        }
      } else {
        result = odysseyAgentContent.forMarketer;
      }
      context.commit('SET_IS_MARKETER_SITE', result);
    },
    FETCH_IS_MULTIPLE_COUPON_STATE: (context) => {
      const { marketerId, odysseyAgentContent, marketerAgencyContent, isCategoryLinkAgency } = context.state;
      const isMainOrWhiteLabel = odysseyAgentContent?.forMainSite || odysseyAgentContent?.forWhiteLabelSite;
      let result = false;
      if (isMainOrWhiteLabel) {
        result = marketerId && marketerAgencyContent?.forMarketer;
      } else {
        result = odysseyAgentContent?.forMarketer;
      }

      if (isCategoryLinkAgency) {
        // Temporarily disable the multiple coupon feature when agencyCOde is SDABACH
        result = false;
      }
      context.commit('SET_IS_MULTIPLE_COUPON', result);
    },

    // marketer part. Moved from main store.
    FETCH_MARKETER_ID: async (context, payload) => {
      try {
        const response = await get('BFF', `/operation/marketerId?dealType=${payload}`, context);
        context.commit('SET_MARKETER_ID', response.data);
      } catch (error) {
        context.commit('SET_MARKETER_ID', '');
      }
    },
    FETCH_AGENCY_FROM_MARKETER_ID: async (context) => {
      const { marketerId } = context.state;
      try {
        const response = await get('BFF', `/dealType/byAgency?agencyCode=${marketerId}`, context);
        context.commit('SET_AGENCY_FROM_MARKETER_ID', response.data[0]);
      } catch (error) {
        context.commit('SET_AGENCY_FROM_MARKETER_ID', '');
      }
    },
    FETCH_AGENCY_CONTENT_FROM_MARKETER_ID: async (context) => {
      const { marketerId, agencyAuthorized } = context.state;
      try {
        const response = await get('BFF', `/agencyContents/byAgencyCode/${marketerId}`, context);
        context.commit('SET_AGENCY_CONTENT_MARKETER_ID', response.data);
        context.commit('SET_PAYMENT_OPTION_PARAMS', { minPaymentsForCredit: response.data.minPaymentsForCredit, maxPayments: response.data.maxPayments });

        if (!agencyAuthorized) { context.commit('SET_AGENCY_LOGIN_STATE', response.data.isLoginMarketer); }
      } catch (error) {
        context.commit('SET_AGENCY_CONTENT_MARKETER_ID', null);
      }
    },
    REQUEST_MARKETER_LOGIN_BY_PASSWORD: async (context, payload) => {
      const data = new FormData();
      const { marketerAgencyContent, isFcAgentMarketerMode } = context.state;
      const { password } = payload;
      data.append('password', password);
      context.commit('SET_IS_AGENCY_AUTHORING', true);
      try {
        const response = await post('BFF', '/agency/passlogin', data, context, '', true);
        if (response.data.error) {
          context.commit('SET_AGENCY_ERROR_MESSAGE', response.data.error.message);
          context.commit('SET_AGENCY_SID', null);
        } else {
          context.commit('SET_AGENCY_LOGIN_STATE', false);
          context.commit('SET_AGENCY_ERROR_MESSAGE', null);
          context.commit('SET_AGENCY_AUTHORIZED', true);
          context.commit('SET_AGENCY_USER', marketerAgencyContent);

          if (isFcAgentMarketerMode) {
            window.localStorage.setItem('fcAgentAgencySid', response.data.data.agencySid);
          } else {
            window.localStorage.setItem('agencySid', response.data.data.agencySid);
          }
          window.localStorage.setItem('agencyLogin', response.data.data.logined);
          window.localStorage.setItem('agencyUser', utf8ToB64(JSON.stringify(marketerAgencyContent)));
        }
      } catch (error) {
        context.commit('SET_AGENCY_ERROR_MESSAGE', error);
      }
      context.commit('SET_IS_AGENCY_AUTHORING', false);
    },
    FETCH_ALL_AGENT_LIST: async (context) => {
      try {
        const response = await get('BFF', '/agent/all', context);
        context.commit('SET_ALL_AGENT_LIST', response.data);
        return response;
      } catch (e) {
        return e;
      }
    },
    FETCH_AGENT_LIST_PRICE_PLAN_ONLY: async (context) => {
      try {
        const response = await get('BFF', '/agency/forPricePlanOnly', context);
        context.commit('SET_AGENT_LIST_PRICE_PLAN_ONLY', response.data);
        return response;
      } catch (e) {
        return e;
      }
    },
  },
};
